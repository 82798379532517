import React, { ReactNode } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ReactComponent as Logo } from "../../../images/half-logo.svg" 
import useWindowDimensions from '../../../hook/useWindowDimensions'
import SocialMediaList from './SocialMediaList'
import "./contact.css"
import { useTranslation } from 'react-i18next';

interface ColumnType {
  direction: "flex-start" | "flex-end",
  className: string
}

const Column: React.FC<ColumnType & ReactNode> = (props) => {
  return (
    <Col sm={12} md={4} className={`column ${props.className}`} style={{alignItems: props.direction}}>{props.children}</Col>
  )
}
  
const Contact = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const getDirection = (): any => {
    return width <= 768
      ? ['center', 'center', 'center']
      : ['flex-start', 'center', 'flex-end']
  }
  
  return (
    <Row id="footer-contact">
      <Column direction={getDirection()[0]} className="first">
        <a href="/main-page/#root">
          <Logo className="logo" />
        </a>
      </Column>
      <Column direction={getDirection()[1]} className="second">
        <a className="link" href="https://skey.network/" target="_blank" rel="noreferrer">
          <div className="texts">
            <span className="text">{t('footer.productWebsite.text1')}</span>
            <span className="text second">{t('footer.productWebsite.text2')}</span>
          </div>
          <p style={{ fontSize: '10px', lineHeight: '1.6', marginBottom: '0px' }}>
            {t('footer.productWebsite.text3')}
          </p>
        </a>
      </Column>
      <Column direction={getDirection()[2]} className="third">
        <SocialMediaList />
      </Column>
    </Row>
  )
}

export default Contact
