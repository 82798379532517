import React from 'react'
import { Col, Container, Row, Accordion } from 'react-bootstrap'
import { ReactComponent as FlareBig } from "../../images/flare-big.svg"
import { ReactComponent as FlareMedium } from "../../images/flare-medium.svg"
import { questions } from './questions'
import "./faq-section.css"
import useWindowDimensions from '../../hook/useWindowDimensions'

const FaqSection = () => {
  const { width } = useWindowDimensions()

  const getFlare = (style: {}, flareType: "big" | "medium") => {
    return flareType === "big" ? (
      <FlareBig style={style} />
    ) : flareType === "medium" && (
      <FlareMedium style={style} />
    )
  }

  const flaresStyle = () => {
    return width <= 576
      ? [
          { position: "absolute", top: "-1382px" ,left: "-150px", zIndex: "2", transform: "scale(4)" },
          { position: "absolute", bottom: "-1131px" ,left: "50%", zIndex: "2", transform: "translateX(-50%) scale(3)" }
        ]
      : [
          { position: 'absolute', top: '-1483px', left: '-594px', zIndex: "2" },
          { position: 'absolute', left: "50%", transform: "translateX(-50%) scale(1.5)", bottom: "-1352px", zIndex: "2" }
        ]
  }
  
  return (
    <section id="faq-section" style={{ position: 'relative' }}>
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row>
            <Col xs={12}>
              <h2 className="title">FAQ</h2>
              <Accordion>
                {questions.map((question: any) => {
                  return (
                    <Accordion.Item eventKey={question.id.toString()} key={question.id}>
                      <Accordion.Header>{question.question}</Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {question.answers.map((answer: string) => {
                            return (
                              <React.Fragment key={answer}>
                                {question.style === 'flex' ? (
                                  <Col xs={12}>
                                    <p className="answer">{answer}</p>
                                  </Col>
                                ) : (
                                  <Col lg={4} sm={6} xs={12}>
                                    <p
                                      className="answer"
                                      style={question.margin && { marginBottom: question.margin }}
                                    >
                                      {answer}
                                    </p>
                                  </Col>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </Col>
            {getFlare(flaresStyle()[0], 'big')}
            {getFlare(flaresStyle()[1], 'medium')}
          </Row>
        </Container>
      </Container>
    </section>
  )
}

export default FaqSection
