export const questions = [
  {
    id: 1,
    style: 'normal',
    question: 'What is Skey Network?',
    answers: [
      'Skey Network is the only connector that combines the Internet of Things devices with Blockchain technology, creating the Blockchain of Things. Our goal is to become the communication standard for Internet of Things devices just as Bluetooth technology is the standard for wireless connectivity.',
      'Skey Network combines Oracle, Blockchain of Things and decentralized finances (DeFi) technologies to create a unique Access Key called Smart NFT. It is safe, universal, transparent and connects various devices and technologies from various producers and suppliers.'
    ]
  },
  {
    id: 2,
    style: 'normal',
    question: 'How does it work?',
    margin: '30px',
    answers: [
      'Skey Network provides blockchain managed access to assets such as cars, homes, and hotels. To generate a unique access token called the Smart NFT, our connector combines three unique technologies – Blockchain of Things (BoT), 2nd generation Oracle and DeFi access.',
      'We are the first working platform to allow you to combine such technologies. Using these combined technologies these unique tokens can then be sold using a simple website or application, used to build new business models. This creates a solution that will become critical to the sharing economy of the 21st century.',
      'Skey Network will bring NFT (non-fungible token) technology to a whole new level, where it will be used for more than cryptocurrencies.',
      'Skey Network technology will bring NFT real physical value  (realNFT) and expand the use of multiple utilities throughout the world.'
    ]
  },
  {
    id: 3,
    style: 'normal',
    question: 'Skey Network Challenge',
    answers: [
      'An international competition for projects based on the technology and infrastructure of the Skey Network. Our task is to select the best concepts, solutions and ready-made products for developing the Skey Network ecosystem. The aim of this action is to support companies, startups and individual enthusiasts to bring their projects to life with our financial assistance.'
    ]
  },
  {
    id: 4,
    style: 'normal',
    question: 'Who can apply?',
    answers: [
      'Developers, individually or in groups, as well as companies, startups and individual enthusiasts, are allowed to register for participation. Any person who wants to participate has to be 18 years of age or older.'
    ]
  },
  {
    id: 5,
    style: 'normal',
    question: 'What we mean by project?',
    answers: [
      'Product or service or concept (idea) made dedicated to/based on Skey Network which provides solutions in the following areas: Smart City, Sharing Economy, ORACLE, Security, DeFi, NFT.'
    ]
  },
  {
    id: 6,
    style: 'normal',
    question: 'What are the conditions?',
    answers: [
      'All terms and regulations related to the competition can be found in the competition conditions, which are available https://challenge.skey.network/main-page/#tos'
    ]
  },
  {
    id: 7,
    style: 'normal',
    question: 'Is the documentation closed?',
    answers: [
      'No. The documentation is still being developed so if you have any questions, suggestions or proposals write to us at challenge@skey.network'
    ]
  },
  {
    id: 8,
    style: 'normal',
    question: 'How post should look like?',
    answers: [
      'The post must be written in English and have added following hashtags: #skeyecosystem #skeyblockchain #skeynetworkchallenge #skey #skeynetwork'
    ]
  },
  {
    id: 9,
    style: 'normal',
    question: 'What media should the posts be published on?',
    answers: [
      'We do not indicate any specific media that the post is to be published on. We leave you freedom of choice for this,  but please make your posts wide-ranging.  Be sure to include the link to the post in the submission form on the challenge platform.'
    ]
  },
  {
    id: 10,
    style: 'flex',
    question: 'If I have any questions, where should I direct them?',
    answers: [
      'Please send your questions to our dedicated:',
      'e-mail - challenge@skey.network',
      'Challenge Telegram for general issues: Skey Network Challenge GENERAL',
      'Challenge Telegram for technical issues: Skey Network Challenge TECHNICAL'
    ]
  },
  {
    id: 11,
    style: 'normal',
    question: 'Definitions',
    margin: '30px',
    answers: [
      'Smart City - is a technologically modern urban area that uses different types of electronic methods, voice activation methods and sensors to collect specific data. The information gained from that data is used to manage assets, resources and services efficiently; in return, that data is used to improve the operations across the city. This includes data collected from citizens, devices, buildings and assets that are then processed and analysed to monitor and manage traffic and transportation systems, power plants, utilities, water supply networks, waste, crime detection, information systems, schools, libraries, hospitals, and other community services.',
      'ORACLE- A blockchain oracle is a third-party service that connects smart contracts with the outside world, primarily to feed information from the world, but also the reverse. Information from the world encapsulates multiple sources so that decentralised (single central authority-free) knowledge is obtained. Information to the world includes making payments and notifying parties. The oracle is the layer that queries, verifies, and authenticates external data sources, usually via trusted API, and the internet of things feeds and then relays, that information.',
      'the Sharing Economy - an economic system in which assets or services are shared between private individuals, either free or for a fee, typically by means of the internet.',
      'Security - access control (AC) is the selective restriction of access to a place or other resource, while access management describes the process. The act of accessing may mean consuming, entering, or using. Permission to access a resource is called authorization.',
      'DeFi - Decentralized finance, is a system by which financial products become available on a public decentralized blockchain network. DeFi is distinct because it expands the use of blockchain from simple value transfer to more complex financial use cases.',
      'NFT - A non-fungible token is a unique and non-interchangeable unit of data stored on a digital ledger (blockchain).'
    ]
  }
]