import React from 'react'
import { Col } from 'react-bootstrap'
import { ReactComponent as FlareSmall } from "../../../images/flare.svg"
import { ReactComponent as FlareBig } from "../../../images/flare-big.svg"

interface StepType {
  side: 'left' | 'right'
  step: number
  stage: string
  expiration: string
  heading: string
  text: string
  hasBottomText: boolean
  bottomText1: string
  bottomText2: string
  withButton: boolean
  buttonText?: string
  linkToImage: string
  xPos: number
  yPos: number
  height: number
  flareSize?: '1' | '2'
  flareStyle?: {}
}

const Step: React.FC<StepType> = (props) => {

  const getBottomText = () => {
    return (
      props.hasBottomText && (
        <div>
          <span className="text-orange">{props.bottomText1}</span>
          <span className="text-orange bold">{props.bottomText2}</span>
        </div>
      )
    )
  }

  const content = () => {
    return (
      <>
        <div className="content">
          <h3 className="step">{props.step}</h3>
          <h5 className="stage">{props.stage}</h5>
          <span className="expiration">{props.expiration}</span>
          <h3 className="heading">{props.heading}</h3>
          <p className="text">{props.text}</p>
          {getBottomText()}
          {props.withButton ? <button className="c2a">Join Now!</button> : <></>}
        </div>
        <img
          src={props.linkToImage}
          alt="Skey Network"
          className="image"
          style={{ left: props.xPos, top: props.yPos }}
        />
      </>
    )
  }

  const getFlare = () => {
    return props.flareSize === '1' ? (
      <FlareSmall style={props.flareStyle} />
    ) : (
      props.flareSize === '2' && 
      (<FlareBig style={props.flareStyle} />)
    )
  }

  return (
    <div className="stage-row">
      {getFlare()}
      {props.side === 'left' ? (
        <Col
          id="step-left"
          className="step-col"
          lg={{ span: 4, offset: 1 }}
          sm={{ span: 10, offset: 1 }}
          style={{ minHeight: props.height }}
        >
          {content()}
        </Col>
      ) : (
        <Col
          id="step-right"
          className="step-col"
          lg={{ span: 4, offset: 7 }}
          sm={{ span: 10, offset: 1 }}
          style={{ minHeight: props.height }}
        >
          {content()}
        </Col>
      )}
    </div>
  )
}

export default Step
