import { ReactComponent as Twitter } from '../../../images/footer/twitter.svg'
import { ReactComponent as Telegram } from '../../../images/footer/telegram.svg'
// import { ReactComponent as Reddit } from '../../../images/footer/reddit.svg'
// import { ReactComponent as Discord } from '../../../images/footer/discord.svg'
import { ReactComponent as Facebook } from '../../../images/footer/facebook.svg'
import { ReactComponent as YouTube } from '../../../images/footer/youtube.svg'
import { ReactComponent as GitHub } from '../../../images/footer/github.svg'
import SocialMedia from './SocialMedia'

const SocialMedialList = () => {
  const fill = '#424242'

  return (
    <div className="social-media">
      <SocialMedia href="https://twitter.com/SkeyNetwork">
        <Twitter fill={fill} />
      </SocialMedia>
      <SocialMedia href="https://t.me/SmartKeyNews">
        <Telegram fill={fill} />
      </SocialMedia>
      <SocialMedia href="https://www.youtube.com/c/SkeyNetwork">
        <YouTube fill={fill} />
      </SocialMedia>
      {/* <SocialMedia href="https://www.reddit.com/r/Smartkeyplatform/">
        <Reddit fill={fill} />
      </SocialMedia> */}
      {/* <SocialMedia href="#">
        <Discord fill={fill} />
      </SocialMedia> */}
      <SocialMedia href="https://www.facebook.com/SkeyNetwork">
        <Facebook fill={fill} />
      </SocialMedia>
      <SocialMedia href="https://github.com/skey-network">
        <GitHub fill={fill} />
      </SocialMedia>
    </div>
  )
}

export default SocialMedialList
