import { TranslationProvider } from "./context/translationContext"
import Routing from "./routing/Routing"
import './translation/Translation'

function App() {
  return (
    <TranslationProvider>
        <div className="App">
          <Routing />
        </div>
    </TranslationProvider>
  )
}

export default App
