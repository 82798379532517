import { Row } from 'react-bootstrap'

import BulletPoint from './BulletPoint'
import { useTranslation } from 'react-i18next';

const BulletPoints = () => {
  const { t } = useTranslation();
  
  return (
    <Row id="bullet-points">
      <BulletPoint content={t('tac.columns.col1.description')} />
      <BulletPoint
        content={t('tac.columns.col2.description')}
        link="https://github.com/skey-network"
      />
      <BulletPoint content={t('tac.columns.col3.description')} />
    </Row>
  )
}

export default BulletPoints
