import React from 'react'
import { ReactComponent as LogoWhite } from '../images/logo-white.svg'
import { ReactComponent as Exit } from '../images/exit.svg'
import './popup.css'
import { useTranslation } from 'react-i18next'

interface PopupType {
  callback: Function
}

const AwardPopup: React.FC<PopupType> = (props) => {
  const { t } = useTranslation()

  return (
    <div
      id="backdrop"
      onClick={() => props.callback(false)}
      onScroll={(e) => e.stopPropagation()}
      onScrollCapture={(e) => e.stopPropagation()}
    >
      <div className="content-box" onClick={(e) => e.stopPropagation()}>
        <LogoWhite className="logo" />
        <Exit className="exit" type="button" onClick={() => props.callback(false)} />
        <h1 className="info">{t('awardPopup.text')}</h1>
        <div className="row">
          <div className="col d-none d-sm-block col-lg-4">
            <p className="numbers">{t('awardPopup.project1')}</p>
          </div>
          <div className="col d-none d-sm-block col-lg-4">
            <p className="numbers">{t('awardPopup.project2')}</p>
          </div>
          <div className="col d-none d-sm-block col-lg-4">
            <p className="numbers">{t('awardPopup.project3')}</p>
          </div>
          <div className="col-12 d-block d-sm-none">
            {/* <p className="numbers">{t('winnersPopup.overrideProjects')}</p> */}
            <p className="numbers">{t('awardPopup.project1')}</p>
            <p className="numbers">{t('awardPopup.project2')}</p>
            <p className="numbers">{t('awardPopup.project3')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AwardPopup
