import React from 'react'

import NavBar from "../components/NavBar"
import "../components/navbar.css"

const NavbarLayout: React.FC<any> = (props) => {
  return (
    <>
      <NavBar />
      {props.children}
    </>
  )
}

export default NavbarLayout
