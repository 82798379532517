import MainPage from "../MainPage"
import FaqPage from "../FaqPage"

const Routes: {
  id: number
  path: string
  Component: () => JSX.Element
}[] = [
  {
    id: 1,
    path: '/main-page',
    Component: MainPage
  },
  {
    id: 2,
    path: '/faq-page',
    Component: FaqPage
  }
]

export default Routes
