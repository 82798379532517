import React from 'react'
import FaqSection from './views/faq-section/FaqSection'
import Footer from './views/footer/Footer'
import LogoAndGraphic from './views/logo-and-graphic/LogoAndGraphic'
// import SendApplication from './views/send-application/SendApplication'

const FaqPage = () => {
  return (
    <section id="faq">
      <LogoAndGraphic />
      <FaqSection />
      {/* <SendApplication /> */}
      <Footer />
    </section>
  )
}

export default FaqPage
