import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import WinnersPopup from '../../../components/WinnersPopup'
import useWindowDimensions from '../../../hook/useWindowDimensions'
import { ReactComponent as Logo } from '../../../images/logo.svg'

import './column.css'
import { useTranslation } from 'react-i18next'
import AwardPopup from '../../../components/AwardPopup'

const Column = () => {
  const [winnersPopup, setWinnersPopup] = React.useState<boolean>(false)
  const [awardPopup, setAwardPopup] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const getLogo = () => {
    return width <= 768 ? <></> : <Logo className="logo" />
  }

  const getWinnersPopup = () => {
    return winnersPopup && <WinnersPopup callback={setWinnersPopup} />
    // return <Popup callback={setButtonClicked} />
  }

  const getAwardPopup = () => {
    return awardPopup && <AwardPopup callback={setAwardPopup} />
  }

  return (
    <>
      <img
        src={'/images/header/big-header.png'}
        alt="header-img"
        style={{ position: 'absolute', width: 'auto', objectFit: 'none' }}
      />
      <Container fluid="xxl" id="content">
        <Container fluid="xl">
          <Row className="header-row">
            <Col xl={{ span: 7 }} className="header-col">
              {getLogo()}
              <h1 className="heading">{t('header.title')}</h1>
              <div className="button-wrapper">
                <button className="c2a-button-left" onClick={() => setWinnersPopup(true)}>
                  {t('header.button1')}
                </button>
                <button
                  className="c2a-button-right"
                  onClick={() => {
                    setAwardPopup(true)
                    console.log('popup')
                  }}
                >
                  {t('header.button2')}
                </button>
              </div>
              {/* <hr className="hr" />
              <p className="text">{t('header.voting')}</p>
              <button
                className="vote-button"
                onClick={() =>
                  window.open('https://pro.evalato.com/3957/public-voter/5710', '_blank')
                }
              >
                {t('header.vote')}
              </button> */}
            </Col>
          </Row>
        </Container>
      </Container>
      {getWinnersPopup()}
      {getAwardPopup()}
    </>
  )
}

export default Column
