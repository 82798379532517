import { Col } from 'react-bootstrap'

import bulletPointImage from '../../../images/tos/bullet_point.png'
import { useTranslation } from 'react-i18next';

const BulletPoint: React.FC<{ content: string, link?: string }> = ({ content, link }) => {
  const { t } = useTranslation()
  
  return (
    <Col xs={12} lg={4} className="bullet-point">
      <img src={bulletPointImage} alt="bp" />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>{content}</p>
        {link && (
          <>
            {/* <a href={link} target="_blank" rel="noreferrer" className="show-github">
              {'show GitHub >'}
            </a> */}
            <a
              href="https://skey-network.gitbook.io/skey-network/"
              target="_blank"
              rel="noreferrer"
              className="show-github"
            >
              {t('tac.columns.col2.button')}
            </a>
          </>
        )}
      </div>
    </Col>
  )
}

export default BulletPoint
