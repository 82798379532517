import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'

i18next
.use(initReactI18next) // passes i18n down to react-i18next
.use(Backend)
  .init({
    supportedLngs: ['ko', 'en'],
    fallbackLng: 'en',
    backend: {
      loadPath: `/assets/locales/{{lng}}/translation.json`
    },
    react: {
      useSuspense: false
    }
  })

  export default i18next;