import { Container, Row } from 'react-bootstrap'

import Article from './Components/Article'

import cryptoExpo from '../../images/discover/crypto-expo-dubai.png'
import orange from '../../images/discover/orange.png'
import teltonika from '../../images/discover/teltonika-group.png'
import { ReactComponent as Flare} from "../../images/flare-medium.svg"
import './discover.css'
import { useTranslation } from 'react-i18next';


const Discover = () => {

  const { t } = useTranslation()

  const getFlare = () => {
    return (
      <Flare
        style={{ position: 'absolute', right: '514px', bottom: '-835px' }}
        className="flare"
      ></Flare>
    )
  }
  
  return (
    <section id="discover" style={{ position: 'relative' }}>
      <Container fluid="xxl">
        {getFlare()}
        <Container fluid="xl">
          <h1>{t('discover.title')}</h1>

          <Row id="articles">
            <Article
              title={t('discover.columns.col1.heading')}
              image={cryptoExpo}
              description={t('discover.columns.col1.description')}
              bgColor="#5C44EC"
              article="https://www.cryptured.com/future-of-iot-technology-skey-network-wins-the-best-iot-solution-at-the-ced2021/"
            />
            <Article
              title={t('discover.columns.col2.heading')}
              image={orange}
              description={t('discover.columns.col2.description')}
              bgColor="#3495FF"
              article="https://thefintechtimes.com/gitex-global-dubai-2021-round-up-the-uae-leads-financial-services-transformation/"
            />
            <Article
              title={t('discover.columns.col3.heading')}
              image={teltonika}
              description={t('discover.columns.col3.description')}
              bgColor="#BB5DEE"
              article="https://teltonika-gps.com/about-us/pioneering-blockchain-project-for-emergency-services/"
            />
          </Row>
        </Container>
      </Container>
    </section>
  )
}

export default Discover
