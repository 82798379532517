import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as Logo } from '../images/logo.svg'
import { ReactComponent as SmallLogo } from '../images/small-logo.svg'
import useWindowDimensions from '../hook/useWindowDimensions'
import './navbar.css'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../context/translationContext'
// import { Dropdown } from 'react-bootstrap'

const NavBar = () => {
  const { width } = useWindowDimensions()
  const [collapsed, setCollapsed] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const { setLanguage, currentLanguage } = useLanguage()

  const createDropdown = () => {
    return (
      <>
        <option value="en">English</option>
        <option value="ko">Korean</option>
      </>
    )
  }

  const getMenuClasses = () => {
    return collapsed ? 'menu-list collapsed' : 'menu-list'
  }

  const handleHamburger = (targetState: boolean) => {
    setCollapsed(targetState)
  }

  const getLinks = () => {
    return (
      <>
        {/* <Link to="/main-page/#root" className="anchor" onClick={() => setCollapsed(false)}>
          Idea
        </Link> */}
        <Link to="/main-page/#roadmap" className="anchor" onClick={() => setCollapsed(false)}>
          {t('navbar.nav1')}
        </Link>
        <Link to="/main-page/#categories" className="anchor" onClick={() => setCollapsed(false)}>
          {t('navbar.nav2')}
        </Link>
        <Link to="/main-page/#tos" className="anchor" onClick={() => setCollapsed(false)}>
          {t('navbar.nav3')}
        </Link>
        <Link to="/main-page/#discover" className="anchor" onClick={() => setCollapsed(false)}>
          {t('navbar.nav4')}
        </Link>
        <Link to="/main-page/#contact" className="anchor" onClick={() => setCollapsed(false)}>
          {t('navbar.nav5')}
        </Link>
        <Link to="/faq-page/#faq" className="anchor" onClick={() => setCollapsed(false)}>
          {t('navbar.nav6')}
        </Link>
        <select
          className="anchor"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setLanguage(e.target.value)}
          value={currentLanguage === 'en' ? 'en' : 'ko'}
        >
          {createDropdown()}
        </select>
      </>
    )
  }

  const conetent = (width: number) => {
    return width >= 768 ? (
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row style={{ width: '100%' }} className="navbar-row">
            <Col sm={{ span: 1, offset: 0 }}>
              <Link
                to="/main-page/#root"
                style={{ width: '100%', height: '100%' }}
                className="navbar-logo-col"
              >
                <SmallLogo />
              </Link>
            </Col>
            <Col
              xl={{ span: 8, offset: 3 }}
              lg={{ span: 9, offset: 2 }}
              md={{ span: 10, offset: 1 }}
              className="navbar-col"
            >
              {getLinks()}
            </Col>
          </Row>
        </Container>
      </Container>
    ) : (
      <>
        <div className="mobile-menu">
          <Link
            to="/main-page/#root"
            style={{ width: '100%', height: '100%' }}
            className="navbar-logo-col"
          >
            <Logo className="logo" />
          </Link>
          <button className="hamburger" onClick={() => handleHamburger(!collapsed)}></button>
        </div>
        <div className={getMenuClasses()} onClick={() => setCollapsed(false)}>
          {getLinks()}
        </div>
      </>
    )
  }

  return <nav id="navigation-bar">{conetent(width)}</nav>
}

export default NavBar
