import React from 'react'
import { Container } from 'react-bootstrap'
import LogoSection from './components/LogoSection'
import RoadmapColumns from './components/RoadmapColumns'
import Stages from './components/Stages'
import "./roadmap.css"
import "./components/components.css"

const Roadmap = () => {
  return (
    <section id="roadmap">
      <div className="gradient" >
      <Container fluid="xxl">
        <Container fluid="xl">
            <LogoSection />
            <RoadmapColumns />
        </Container>
      </Container>
      </div>
      <Container fluid="xxl">
        <Container fluid="xl">
          <Stages />
        </Container>
      </Container>
    </section>
  )
}

export default Roadmap
