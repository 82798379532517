import React from 'react'
import Categories from './views/categories/Categories'
import Discover from './views/discover/Discover'
import Footer from './views/footer/Footer'
import Header from './views/header/Header'
import Roadmap from './views/roadmap/Roadmap'
// import SendApplication from './views/send-application/SendApplication'
import Tos from './views/tos/Tos'
// import Traits from './views/traits/Traits'

const MainPage = () => {
  return (
    <>
      <Header />
      {/* <Traits /> */}
      <Roadmap />
      <Categories />
      <Tos />
      <Discover />
      {/* <SendApplication /> */}
      <Footer />
    </>
  )
}

export default MainPage
