import React from 'react'
import { ReactComponent as LogoWhite } from '../images/logo-white.svg'
import { ReactComponent as Exit } from '../images/exit.svg'
import './popup.css'
import { useTranslation } from 'react-i18next'

interface PopupType {
  callback: Function
}

const WinnersPopup: React.FC<PopupType> = (props) => {
  const { t } = useTranslation()

  return (
    <div
      id="backdrop"
      onClick={() => props.callback(false)}
      onScroll={(e) => e.stopPropagation()}
      onScrollCapture={(e) => e.stopPropagation()}
    >
      <div className="content-box" onClick={(e) => e.stopPropagation()}>
        <LogoWhite className="logo" />
        <Exit className="exit" type="button" onClick={() => props.callback(false)} />
        <h1 className="info">{t('winnersPopup.text')}</h1>
        {/* <p className="description">{t('popup.description')}</p> */}
        <div className="row">
          <div className="col d-none d-sm-block col-lg-4">
            <p className="numbers">{t('winnersPopup.projects1')}</p>
            <p className="numbers">{t('winnersPopup.projects2')}</p>
            <p className="numbers">{t('winnersPopup.projects3')}</p>
            <p className="numbers">{t('winnersPopup.projects4')}</p>
          </div>
          <div className="col d-none d-sm-block col-lg-4">
            <p className="numbers">{t('winnersPopup.projects5')}</p>
            <p className="numbers">{t('winnersPopup.projects6')}</p>
            <p className="numbers">{t('winnersPopup.projects7')}</p>
          </div>
          <div className="col d-none d-sm-block col-lg-4">
            <p className="numbers">{t('winnersPopup.projects8')}</p>
            <p className="numbers">{t('winnersPopup.projects9')}</p>
            <p className="numbers">{t('winnersPopup.projects10')}</p>
          </div>
          <div className="col-12 d-block d-sm-none">
            {/* <p className="numbers">{t('winnersPopup.overrideProjects')}</p> */}
            <p className="numbers">{t('winnersPopup.projects1')}</p>
            <p className="numbers">{t('winnersPopup.projects2')}</p>
            <p className="numbers">{t('winnersPopup.projects3')}</p>
            <p className="numbers">{t('winnersPopup.projects4')}</p>
            <p className="numbers">{t('winnersPopup.projects5')}</p>
            <p className="numbers">{t('winnersPopup.projects6')}</p>
            <p className="numbers">{t('winnersPopup.projects7')}</p>
            <p className="numbers">{t('winnersPopup.projects8')}</p>
            <p className="numbers">{t('winnersPopup.projects9')}</p>
            <p className="numbers">{t('winnersPopup.projects10')}</p>
          </div>
        </div>
        {/* <div className="buttons-wrapper">
          <a href="/main-page/#roadmap">
            <button className="button one" onClick={() => props.callback(false)}>
              {t('popup.button1')}
            </button>
          </a>
          <a href="/main-page/#contact">
            <button className="button two" onClick={() => props.callback(false)}>
              {t('popup.button2')}
            </button>
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default WinnersPopup
