import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../context/translationContext'

const Main = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useLanguage()

  return (
    <Row id="main-row">
      <Col md={6} sm={12} style={{ zIndex: 4 }}>
        <h1>{t('tac.title')}</h1>
        <h3>{t('tac.heading')}</h3>

        <ol>
          <li>{t('tac.criteria.crit1')}</li>
          <li>{t('tac.criteria.crit2')}</li>
          <li>{t('tac.criteria.crit3')}</li>
          <li>{t('tac.criteria.crit4')}</li>
          <li>{t('tac.criteria.crit5')}</li>
          <li>{t('tac.criteria.crit6')}</li>
          <li>{t('tac.criteria.crit7')}</li>
        </ol>
        <a
          href={
            currentLanguage === 'en'
              ? `/pdf/Skey_Network_Challenge_Terms_And_Conditions_${currentLanguage}_ver_3.0.pdf`
              : `/pdf/Skey_Network_Challenge_Terms_And_Conditions_${currentLanguage}.pdf`
          }
          target="_blank"
          rel="noreferrer"
          className="show-terms"
        >
          {t('tac.button')}
        </a>
      </Col>
    </Row>
  )
}

export default Main
