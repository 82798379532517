import { Container } from 'react-bootstrap'

import Main from './Components/Main'
import BulletPoints from './Components/BulletPoints'
import SkeyNetworkSplash from '../../images/tos/skey-network-splash.svg'
import { ReactComponent as Flare } from "../../images/flare-medium.svg"

import './tos.css'

const Tos: React.FC<{}> = () => {
  return (
    <section id="tos">
      <Container fluid="xxl" style={{ position: 'relative' }}>
        <Container fluid="xl">
          <Flare
            style={{ position: 'absolute', left: '-1278px', top: '-1123px', zIndex: 1 }}
            className="flare"
          />
          <Main />
          <div className="gradient" />
          <BulletPoints />
          <img src={SkeyNetworkSplash} alt="Skey Network Splash" className="lines" />
        </Container>
      </Container>
    </section>
  )
}

export default Tos
