import React from 'react'

interface SocialMediaProps {
  href?: string
  children?: React.ReactNode
}

const SocialMedia: React.FC<SocialMediaProps> = ({ href, children }) => {
  return (
    <a href={href ?? '#'} target="_blank" rel="noreferrer" className="social-media-link">
      {children}
    </a>
  )
}

export default SocialMedia
