import React from 'react'
import { Navigate } from 'react-router-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavbarLayout from '../layout/NavbarLayout'
import routes from './routes'

const Routing = () => {
  return (
    <Router>
      <Routes>
        {routes.map((route) => {
          const { id, path, Component } = route

          return (
            <Route
              key={id}
              path={path}
              element={
                <NavbarLayout>
                  <Component />
                </NavbarLayout>
              }
            ></Route>
          )
        })}
        <Route path="/" element={<Navigate to="/main-page" />}></Route>
      </Routes>
    </Router>
  )
}

export default Routing
