import { Container, Row } from 'react-bootstrap'

import { ReactComponent as RawLogo } from "../../images/small-logo.svg"
import { ReactComponent as Flare } from '../../images/flare.svg'
import Contact from './components/Contact'
import Telegram from './components/Telegram'

// import { ReactComponent as Logo } from '../../images/logo.svg'
// import { ReactComponent as Spiral } from '../../images/footer/spiral.svg'
// import SocialMedialList from './components/SocialMediaList'

import './footer.css'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const getFlare = (style: any) => {
    return <Flare style={style} className="flare"></Flare>
  }
  
  return (
    <footer id="contact">
      {/* {getFlare({position: "absolute", right: "-430px", top: "-407px", zIndex: "1"})} */}
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row>
            <div className="footer-content">
              <RawLogo className="logo" />
              <h2 className="askUs">{t('footer.heading')}</h2>
              <p className="contact-email">{t('footer.email')}</p>
              <Telegram />
              <div className="divider" />
              <Contact />
            </div>
          </Row>
        </Container>
      </Container>
      {getFlare({position: "absolute", left: "50%", transform: "translateX(-50%)", bottom: "-565px", zIndex: "1"})}
    </footer>
  )
}

export default Footer
