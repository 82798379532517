import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

interface ArticleProps {
  title: string
  image: string
  description: string
  bgColor: string
  article: string
}

const Article: React.FC<ArticleProps> = ({ title, image, description, bgColor, article }) => {
  const { t } = useTranslation();
  
  return (
    <Col xs={{span: 12, offset: 0}} sm={{span: 10, offset: 1}} md={{span:8, offset: 2}} lg={{span: 6, offset: 3}} xl={{span: 4, offset: 0}} className="article">
      <div className="article-title-container">
        <h1>{title}</h1>
      </div>

      <div className="article-img-container">
        <img className="article-img" src={image} alt="article-img" />

        <div
          className="article-img color-mask"
          style={{
            backgroundColor: bgColor
          }}
        />
      </div>

      <p>{description}</p>
      <a href={article} target="_blank" rel="noreferrer">{t('discover.columns.col1.button')}</a>
      {/* <p></p> */}
    </Col>
  )
}

export default Article
