import React from 'react'
import { Row } from 'react-bootstrap'
import Step from './Step'
import Planet from '../../../images/Skey-Network-Planet.png'
import Volcano from '../../../images/Skey-Network-Volcano.png'
import Cube from '../../../images/Skey-Network-Cube.png'
import Sheet from '../../../images/Skey-Network-Sheet2.svg'
import PlanetSmall from '../../../images/Skey-Network-Planet-Small.png'
import VolcanoSmall from '../../../images/Skey-Network-Volcano-Small.png'
import CubeSmall from '../../../images/Skey-Network-Cube-Small.png'
import useWindowDimensions from '../../../hook/useWindowDimensions'
import { useTranslation } from 'react-i18next'

const Stages = () => {
  const { width } = useWindowDimensions()
  const { t } = useTranslation()

  const getXPos = () => {
    return [304, -463, 327, -906]
  }

  const getYPos = () => {
    return width <= 991 ? [-253, -85, 107, 38] : [454, 296, 523, 372]
  }

  const getHeight = () => {
    return width <= 991 ? [563, 958, 1081, 986] : [872, 965, 987, 1064]
  }

  const getImg = () => {
    return width <= 991
      ? [PlanetSmall, VolcanoSmall, CubeSmall, Sheet]
      : [Planet, Volcano, Cube, Sheet]
  }

  const getFlareStyles = () => {
    return width <= 991
      ? [
          {
            position: 'absolute',
            left: 'unset',
            right: '-608px',
            top: '-921px',
            transform: 'scale(0.7)'
          },
          {
            position: 'absolute',
            right: '-1626px',
            left: 'unset',
            bottom: '-674px',
            transform: 'scale(0.3)',
            zIndex: '1'
          }
        ]
      : [
          { position: 'absolute', left: '-668px', right: 'unset', top: '-395px' },
          { position: 'absolute', right: '-1986px', left: 'unset', bottom: '-951px', zIndex: '1' }
        ]
  }

  return (
    <Row id="stages">
      <Step
        side={'left'}
        step={1}
        stage={t('roadmap.content.card1.title')}
        expiration={t('roadmap.content.card1.time')}
        heading={t('roadmap.content.card1.stageName')}
        text={t('roadmap.content.card1.description')}
        hasBottomText={true}
        bottomText1={t('roadmap.content.card1.deadline.first')}
        bottomText2={t('roadmap.content.card1.deadline.second')}
        withButton={false}
        linkToImage={getImg()[0]}
        xPos={getXPos()[0]}
        yPos={getYPos()[0]}
        height={getHeight()[0]}
        flareSize="1"
        flareStyle={getFlareStyles()[0]}
      />
      <Step
        side={'right'}
        step={2}
        stage={t('roadmap.content.card2.title')}
        expiration={t('roadmap.content.card2.time')}
        heading={t('roadmap.content.card2.stageName')}
        text={t('roadmap.content.card2.description')}
        hasBottomText={false}
        bottomText1={t('roadmap.content.card2.deadline.first')}
        bottomText2={t('roadmap.content.card2.deadline.second')}
        withButton={false}
        linkToImage={getImg()[1]}
        xPos={getXPos()[1]}
        yPos={getYPos()[1]}
        height={getHeight()[1]}
      />
      <Step
        side={'left'}
        step={3}
        stage={t('roadmap.content.card3.title')}
        expiration={t('roadmap.content.card3.time')}
        heading={t('roadmap.content.card3.stageName')}
        text={t('roadmap.content.card3.description')}
        hasBottomText={true}
        bottomText1={t('roadmap.content.card3.deadline.first')}
        bottomText2={t('roadmap.content.card3.deadline.second')}
        withButton={false}
        linkToImage={getImg()[2]}
        xPos={getXPos()[2]}
        yPos={getYPos()[2]}
        height={getHeight()[2]}
        flareSize="2"
        flareStyle={getFlareStyles()[1]}
      />
      <Step
        side={'right'}
        step={4}
        stage={t('roadmap.content.card4.title')}
        expiration={t('roadmap.content.card4.time')}
        heading={t('roadmap.content.card4.stageName')}
        text={t('roadmap.content.card4.description')}
        hasBottomText={false}
        bottomText1={t('roadmap.content.card4.deadline.first')}
        bottomText2={t('roadmap.content.card4.deadline.second')}
        withButton={false}
        linkToImage={getImg()[3]}
        xPos={getXPos()[3]}
        yPos={getYPos()[3]}
        height={getHeight()[3]}
      />
    </Row>
  )
}

export default Stages
