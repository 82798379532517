import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ReactComponent as TelegramIcon } from "../../../images/telegram-icon.svg"
// import { ReactComponent as TelegramDisabled } from "../../../images/footer/telegram-mobile.svg"
import "./telegram.css"
import { useTranslation } from 'react-i18next';

const Telegram = () => {
  const { t } = useTranslation();
  return (
    <Row id="telegram">
      <Col sm={12} md={4} className="column">
        <p className="low">{t('footer.telegram.tel1.text1')}</p>
        <p className="high">{t('footer.telegram.tel1.text2')}</p>
        <a href="https://t.me/SmartKeyNews" target="_blank" rel="noreferrer" style={{ zIndex: 4 }}>
          <TelegramIcon />
        </a>
      </Col>
      <Col sm={12} md={4} className="column">
        <p className="low">{t('footer.telegram.tel2.text1')}</p>
        <p className="high">{t('footer.telegram.tel2.text2')}</p>
        <a
          href="https://t.me/skeyNetworkChallengeGeneral"
          target="_blank"
          rel="noreferrer"
          style={{ zIndex: 4 }}
        >
          <TelegramIcon />
        </a>
      </Col>
      <Col sm={12} md={4} className="column">
        <p className="low">{t('footer.telegram.tel3.text1')}</p>
        <p className="high">{t('footer.telegram.tel3.text2')}</p>
        <a
          href="https://t.me/skeyNetworkChallengeTech"
          target="_blank"
          rel="noreferrer"
          style={{ zIndex: 4 }}
        >
          {/* <TelegramDisabled color="gray" type="button" className="disabled-link" height={48} width={48}/> */}
          <TelegramIcon />
        </a>
      </Col>
    </Row>
  )
}

export default Telegram
