import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const RoadmapColumns = () => {
  const { t } = useTranslation()
  return (
    <Row id="roadmap-columns">
      <h2 className="title">{t('roadmap.title')}</h2>
      <Col className="column" sm={{ span: 12 }} md={{ span: 6 }} xl={{ span: 4 }}>
        <p className="text">{t('roadmap.columns.column1')}</p>
      </Col>
      <Col className="column" sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
        <p className="text">{t('roadmap.columns.column2')}</p>
      </Col>
      <Col className="column" sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
        <p className="text">{t('roadmap.columns.column3')}</p>
      </Col>
    </Row>
  )
}

export default RoadmapColumns
