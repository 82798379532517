import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Category from './components/Category'
import CategoryImg from '../../images/category.svg'
import { ReactComponent as Flare } from "../../images/flare.svg"
import { useTranslation } from 'react-i18next'
import './categories.css'

const Categories = () => {
  const { t } = useTranslation();

  const getFlare = (style: any, index: string) => {
    return (
      <Flare style={style} className={`flare ${index}`}></Flare>
    )
  }
  
  return (
    <div id="categories">
      <Container fluid="xxl" style={{ position: 'relative' }}>
        {getFlare({ position: 'absolute', left: '-546px', top: '-568px' }, 'one')}
        {getFlare({ position: 'absolute', right: '-564px', top: '-112px' }, 'two')}
        <Container fluid="xl">
          <h2 className="title">{t('categories.title')}</h2>
          <Row>
            <Category
              imageSource={CategoryImg}
              heading={t('categories.columns.col1.heading')}
              text={t('categories.columns.col1.description')}
            />
            <Category
              imageSource={CategoryImg}
              heading={t('categories.columns.col2.heading')}
              text={t('categories.columns.col2.description')}
            />
            <Category
              imageSource={CategoryImg}
              heading={t('categories.columns.col3.heading')}
              text={t('categories.columns.col3.description')}
            />
            <Category
              imageSource={CategoryImg}
              heading={t('categories.columns.col4.heading')}
              text={t('categories.columns.col4.description')}
            />
            <Category
              imageSource={CategoryImg}
              heading={t('categories.columns.col5.heading')}
              text={t('categories.columns.col5.description')}
            />
            <Category
              imageSource={CategoryImg}
              heading={t('categories.columns.col6.heading')}
              text={t('categories.columns.col6.description')}
            />
          </Row>
        </Container>
      </Container>
    </div>
  )
}

export default Categories;