import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ReactComponent as Logo } from "../../images/logo.svg"
import Wave from "../../images/Skey-Network-Wave.png"
import "./logo-and-graphic.css"

const LogoAndGraphic = () => {
  return (
    <section id="logo-and-graphic">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row className="skey-row">
            <Col xs={4}>
              <Logo className="logo" />
            </Col>
          </Row>
          <img src={Wave} alt="Skey Network Wave" className="wave" />
        </Container>
      </Container>
    </section>
  )
}

export default LogoAndGraphic
