import React from 'react'
import { Col } from 'react-bootstrap'

interface CategoryType {
  imageSource: string
  heading: string
  text: string
}

const Category: React.FC<CategoryType> = (props) => {
  return (
    <Col xl={{ span: 4 }} md={{span: 6}} className="column">
      <img src={props.imageSource} alt="Skey Network category" className="image"></img>
      <h4 className="heading">{props.heading}</h4>
      <p className="text">{props.text}</p>
    </Col>
  )
}

export default Category
