import React from 'react'
import { Row } from 'react-bootstrap'
import {ReactComponent as Logo} from "../../../images/logo.svg"

const LogoSection = () => {
  return (
    <Row id="logo-section">
      <Logo />
    </Row>
  )
}

export default LogoSection
