import Column from './components/Column'
import './header.css'

const Header = () => {
  return (
    <section id="header">
      <Column />
    </section>
  )
}

export default Header
